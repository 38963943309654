/*variables*/

:root {
  --text-white: #ffffff;
  --text-black: #000000;
  --btn-danger: #bf1e2e;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --text-black-color: #231f20;
  --border-color: #a19fa0;
  --primary-btn-color: #bf1e2e;
  --main-body-bg: #f2f2f2;
  --gray-secondary-color: #d4d4d4;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  --webkit-box-shadow: 0 0 0 40px white inset !important;
}
.btn-dark {
  border: 1px solid var(--text-black);
  background-color: var(--text-black) !important;
  color: var(--text-white);
  opacity: 1;
}
.btn-dark:hover,
btn-dark:active,
btn-dark:focus {
  background-color: var(--text-black);
  color: var(--text-white);
  box-shadow: none;
}
.form-check-input:checked {
  background-color: var(--btn-danger);
  border-color: var(--btn-danger);
}
.form-check-input:focus {
  border-color: var(--btn-danger);
  outline: 0;
  /*box-shadow: 0 0 0 0.25rem rgb(191 30 46 / 25%);*/
  box-shadow: none;
}
.pd-right-30 {
  padding-right: 30px !important;
}
.pd-right-70 {
  padding-right: 70px !important;
}
.pd-right-90 {
  padding-right: 90px !important;
}
.mr-4 {
  margin-right: 4rem;
}
.cursor-pointer {
  cursor: pointer;
}
.emp-select-area {
  min-height: 68.4px;
}
.reg-select-area {
  min-height: 62.4px;
}
.emp-gender-mb {
  margin-bottom: 15px !important;
}
.showChildModal {
  background-color: rgba(0, 0, 0, 0.15);
}
.upload-file-section {
  margin-bottom: 40px;
}
.sidebar[data-color="black"]:after {
  background: #fff;
}
.sidebar[data-image]:after {
  opacity: 1;
}
.sidebar .nav li.active .nav-link,
body > .navbar-collapse .nav li.active .nav-link {
  color: var(--text-black-color);
}
.sidebar .nav li .nav-link,
body > .navbar-collapse .nav li .nav-link {
  color: var(--text-black-color);
}
.sidebar .sidebar-wrapper li.active {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}
.sidebar-checkout-img {
  position: absolute;
  bottom: 30px;
  right: 10px;
}
.sidebar-checkout-img img {
  width: 18px;
}
.sidebar .sidebar-wrapper .logo {
  position: relative;
}
.sidebar .sidebar-wrapper .logo:after {
  content: "";
  position: absolute;
  border-radius: 10px;
  top: 100%;
  width: calc(100% - 20px);
  height: 4px;
  left: 10px;
  border-width: 4px;
  background: linear-gradient(to right, #000000, #bf1e2e);
}
.nav-brand-wrapper i {
  color: var(--icon-gray-color);
  font-size: 18px;
}
.nav-brand-wrapper a.navbar-brand {
  font-size: 18px;
  padding-left: 10px;
}
.nav-brand-wrapper a.navbar-brand:hover {
  color: var(--icon-gray-color);
}
.ml-left-desktop {
  margin-left: 10px;
}
/*  */
.registerFormWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}
.profileFormWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}
.pre-register-comp {
  max-width: 500px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  position: relative;
}
.pre-register-comp h3 {
  font-weight: 600;
}
.pre-register-comp .logo-width {
  width: 200px;
}
.pre-register-comp input.form-control {
  font-size: 14px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding-inline: 1rem;
  height: 40px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  line-height: normal;
}
.pre-register-comp input::placeholder {
  color: rgb(130, 130, 130);
}
.pre-register-comp label {
  font-size: 14px;
  font-weight: 400;
}
.pre-register-comp input:active,
.pre-register-comp input:focus {
  border-color: rgb(168, 38, 50) !important;
}
.pre-register-comp p {
  color: rgb(130, 130, 130);
  font-size: 12.96px;
  font-weight: 400;
}
.pre-register-comp a {
  color: rgb(168, 38, 50);
  font-weight: 500;
}
.pre-register-comp .form-group-wrapper .radioBox-wrapper {
  display: inline-block;
  min-width: 85px;
}
.pre-register-comp .form-group-wrapper .PhoneInput {
  width: calc(100% - 100px);
}
.pre-register-comp .form-group-wrapper .PhoneInputInput {
  height: 40px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
}
.pre-register-comp .form-group-wrapper .PhoneInput input {
  height: 40px;
}
.dobInput:read-only {
  background-color: #fff;
}

.border-bottom-1 {
  border-bottom: 1px solid #9a9a9a !important;
}

input:disabled {
  cursor: default;
  background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
  border-color: rgba(118, 118, 118, 0.3);
}

.registerWrapper .signup-btn {
  width: 10 0%;
  height: 38px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: #a82632;
  border-width: 1px;
  border: 1px solid #a82632;
  border-radius: 0;
  opacity: 1;
}
.pre-register-comp .signup-btn:disabled {
  opacity: 0.8;
  background-color: #a82632 !important;
  border-color: #a82632;
}
.codeScannerWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registered-successful {
  font-family: sans-serif;
  text-align: center;
  width: 450px;
}
.registered-successful h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 16px;
}

/*top search bar*/
.searchbar-wrapper input.form-control {
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding-right: 30px;
  background-color: transparent;
  line-height: normal;
}
/*top search bar*/
.searchbar-wrapper input::placeholder {
  color: var(--icon-gray-color);
  font-size: 14px;
}
.searchbar-wrapper > i {
  position: absolute;
  top: 14px;
  right: 10px;
  cursor: pointer;
  font-size: 12px;
  color: var(--icon-gray-color);
}

.user-profile-wrapper {
  border-right: 1px solid var(--border-color);
  padding-inline: 15px;
}
.top-user-profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
}
.top-user-profile img {
  width: 50px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.top-user-profile figcaption {
  font-size: 14px;
  color: var(--text-black-color);
  font-weight: 500;
}
.navbar-logout-btn:hover {
  color: var(--icon-gray-color) !important;
}

/*tr:hover {*/
/*  border: 1px solid transparent;*/
/*  border-color: #ab2330;*/
/*  border-width: 1.1px;*/
/*  margin: -1px;*/
/*}*/
.table > :not(:first-child) {
  border-top: 0;
}
.table > thead {
  border-bottom: 2px solid var(--border-color);
}
.table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
.table tbody tr:last-child {
  border-bottom: 1px solid transparent;
}
.nested-row:hover {
  background-color: transparent;
  border: none;
  border-width: 0;
  margin: 0;
}
.user-table {
  table-layout: fixed;
  width: 100%;
}

.scroll-container {
  will-change: transform;
}

/**/
.card-header > h4.card-title {
  color: var(--text-black-color);
}

.ellipsis {
  max-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.custom-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fixed-table {
  table-layout: fixed;
}
.btn-outline-danger {
  border-color: red;
  color: red;
}
/* .table > tbody > tr > td.tooltip {
  width: auto;
  overflow: visible;
  z-index: 11;
  background-color: #FFFFFF;
} */

.table > tbody > tr > td.icon {
  width: 45px;
  text-overflow: inherit;
  overflow: visible;
  white-space: normal;
}

.table > thead > tr > th.centered {
  text-align: center !important;
}
.table > thead > tr > th.centered > div {
  justify-content: center !important;
}

.hipaaTextArea {
  width: 100%;
  height: 90%;
}
.popOver-centered {
  text-align: center !important;
}
.hipaaDialogue {
  max-height: 100vh;
  max-width: 100vw;
}
.swatch {
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--border-color);
}
.hipaaBody {
  max-height: 90%;
}

/* .modal {
  overflow-x: visible;
  overflow-y: visible;
} */
.modalHeight {
  min-height: 20vh !important;
}
.programPopOverCard {
  min-width: 500px !important;
  min-height: 10vh !important;
}
.eos-employeeModal .modal-dialog .modal-content {
  border: 1px solid var(--border-color) !important;
  box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.5) !important;
}
.eosItemModal-options:hover {
  background-color: ghostwhite;
}
.clickableIcon:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.clickableIcon:active {
  background-color: rgba(255, 255, 255, 0.8);
}
.modalInput {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: var(--text-white);
  border: 1px solid var(--bs-gray-300);
  min-height: 45px;
  line-height: normal;
}
.dateRangeInput {
  border-radius: 5px;
  padding: 2px 10px;
  /*background-color: #f9f9f9;*/
  color: #212529;
  border: 1px solid var(--border-color);
  width: 100%;
  height: 45px;
  cursor: pointer;
  text-align: center;
}
.dateRangeMatrix {
  border-radius: 5px;
  padding: 2px 10px;
  /*background-color: #f9f9f9;*/
  color: #212529;
  border: 1px solid var(--border-color);
  height: 39px;
  cursor: pointer;
  text-align: center;
  /* min-width: 230px !important; */
}

.dateRangeInputField.dateRangeMatrix.headerButton {
  text-align: start;
  place-self: center;
  width: auto;
}

.dateRangeInputField {
  height: auto;
  border: 0px;
  margin: 0;
  padding: 0;
}

.dateSearchBar {
  padding-right: 60px;
  width: 100%;
  align-items: center;
  padding-left: 25px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dateSearchBar .dateBar {
  min-width: fit-content;
  align-items: center;
  flex-direction: column;
  flex-grow: 0.5;
}
.dateSearchBar .globalSearchBar .form-group-wrapper {
  margin-bottom: 0;
}
.dateSearchBar .dateRangeLabel {
  color: var(--light-gray-color);
  font-weight: bold;
  align-self: flex-start;
}

.eosFilterWrapper .form-parent-container {
  row-gap: 0;
}
.eosFilterWrapper .form-parent-container .FormInputGroup {
  width: 100%;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-row-segment .rbc-event-content {
  white-space: normal !important;
}

.rbc-event-continues-after {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rbc-overlay {
  min-width: auto !important;
  padding-right: 100px !important;
}

.rbc-overlay .rbc-event-content .eventSpan {
  padding-right: 200px !important;
}

.testing-calendar .rbc-month-view {
  border: 0;
  border-bottom: 1px solid #ddd;
}
.testing-calendar .rbc-month-view .rbc-month-row {
  flex-direction: column-reverse;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-content-scrollable {
  height: auto;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row {
  margin-bottom: -1px;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell.rbc-now.rbc-off-range {
  background-color: #eaf6ff;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-off-range {
  background-color: #e6e6e6;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell {
  padding-right: 0px;
  position: relative;
  border-left: 1px solid #ddd;
  border-right: 0;
}

/* .testing-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell:has(.blocked-date-header){
  background-color: rgb(137, 132, 131);
} */

.testing-calendar .rbc-month-view .rbc-row.rbc-month-header {
  border-left: 1px solid transparent;
}
.testing-calendar .rbc-month-view .rbc-row.rbc-month-header .rbc-header {
  font-size: 110%;
  padding: 5px 3px;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell:nth-of-type(1) {
  border-left: 1px solid transparent;
}

.custom-schedule-calendar .rbc-month-view .rbc-month-row {
  min-height: 90px;
}
.custom-schedule-calendar .rbc-month-view .rbc-month-row .rbc-row-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.custom-schedule-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-date-cell.rbc-now.rbc-current {
  padding-right: 0;
  border-right: 0;
  height: fit-content;
}
.custom-schedule-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-date-cell.rbc-current {
  background-color: rgba(255, 255, 255, 0);
}

.arrowKeys {
  color: gray;
}

.workingDayIcon {
  width: 18px;
  height: 18px;
}

.notesIcon {
  width: 18px;
  height: 18px;
}

/* .testing-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell:nth-of-type(5) {
  margin-left: -1px;
} */

.testing-calendar .rbc-time-view {
  border: 0;
}
.testing-calendar .rbc-time-view .rbc-label {
  display: none;
}
.testing-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-allday-cell {
  height: auto;
}
.testing-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-allday-cell .rbc-row-bg {
  position: relative;
}
.testing-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-allday-cell .rbc-row-content {
  display: none;
}
.testing-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-row.rbc-time-header-cell {
  /* display: none; */
}
.testing-calendar .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-row.rbc-time-header-cell .rbc-header {
  font-size: 110%;
  padding: 5px 3px;
}
.testing-calendar
  .rbc-time-view
  .rbc-time-header
  .rbc-time-header-content
  .rbc-row.rbc-time-header-cell
  .rbc-header
  .rbc-button-link {
  font-weight: bold;
}
.testing-calendar
  .rbc-time-view
  .rbc-time-header
  .rbc-time-header-content
  .rbc-row.rbc-time-header-cell
  .rbc-header.rbc-today {
  background-color: transparent;
}
.testing-calendar .rbc-time-view .rbc-time-content {
  align-items: initial;
}
.testing-calendar .rbc-time-view .rbc-time-content .rbc-time-gutter.rbc-time-column {
  display: none;
}

.testing-calendar .rbc-time-view .rbc-time-content .rbc-day-slot.rbc-time-column .rbc-events-container {
  position: relative;
}
.testing-calendar .rbc-time-view .rbc-time-content .rbc-day-slot.rbc-time-column .rbc-current-time-indicator {
  display: none;
}
.testing-calendar
  .rbc-time-view
  .rbc-time-content
  .rbc-day-slot.rbc-time-column
  .rbc-timeslot-group
  .rbc-current-time-indicator {
  display: none;
}
.testing-calendar .rbc-time-view .rbc-time-content .rbc-day-slot.rbc-time-column .rbc-timeslot-group:nth-child(2) {
  display: none;
}
.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-bg {
  overflow: visible;
  position: relative;
  min-height: 100px;
}
.eos-testing-calendar .rbc-month-view .rbc-month-row .rbc-row-bg {
  min-height: 80px;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg {
  align-content: flex-start;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg.rbc-off-range-bg .date-container span {
  color: gray;
}

.testing-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg .event {
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin: 1px;
  padding: 4px;
}

.zone-modal-calendar .rbc-month-view .rbc-month-row {
  flex-direction: column-reverse;
  min-height: 180px;
}
.zone-modal-calendar .rbc-month-view .rbc-month-row .rbc-row-bg {
  position: relative;
  overflow-y: visible;
}
.zone-modal-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg {
  /* padding-bottom: 35px; */
}
.zone-modal-calendar .rbc-month-view .rbc-month-row .rbc-row-content {
  /* padding-bottom: 30px; */
  /* margin-bottom: 30px; */
}

.zone-modal-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-date-cell:nth-child(5) {
  margin-right: -1px;
}
.zone-modal-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-date-cell {
  border-right: 1px solid #ddd;
}

.zone-modal-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell.rbc-off-range {
  background-color: #e6e6e6;
}

.rbc-date-cell.rbc-now {
  background-color: #eaf6ff;
  border-right: 1px solid #ddd;
}

/* .rbc-date-cell.rbc-now {
  background-color: rgba(255,255,255,0);
  border-right: 1px solid #ddd;
} */

.zone-toggle-button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}

.question-schedule-calendar .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content {
  /* height: 100%; */
  display: grid;
  align-content: space-between;
}

/* .question-schedule-calendar .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-now.rbc-current:has(.blocked-date-header) {
  background-color: transparent;
}

.zone-modal-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-now.rbc-current:has(.blocked-date-header) {
  background-color: transparent;
} */

.testingCalendarParentDiv {
  display: flex;
}

.testing-calendar {
  width: 80%;
}

.calendar-details {
  width: 20%;
  /* margin-top: 43px;   */
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.calendar-detail-top {
  display: flex;
  justify-content: space-between;
}

.calendar-details .details-container {
  display: grid;
  align-content: space-between;
  height: 100%;
  flex-grow: 1;
  contain: strict;
  min-height: 100px;
}

.calendar-details .details-container .notes-container .MessageInput {
  height: 140px;
  padding: 10px 16px;
  background-color: #f9f9f9;
  width: 100%;
  border: 1px solid #ddd;
}

.calendar-details .details-container .events-container {
  display: flex;
  flex-direction: column;
}

.calendar-details .details-container .events-container .event-bar {
  display: inline-flex;
}
.calendar-details .details-container .events-container .event-bar .event-bar-left {
  width: 1%;
}
.calendar-details .details-container .events-container .event-bar .event {
  color: white;
  cursor: pointer;
  padding: 6px 6px 6px 28px;
  width: 99%;
  position: relative;
}
.calendar-details .details-container .events-container .event-bar .event:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  inset: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.calendar-details .details-container .events-container .event-bar .event .eventName {
  color: #000 !important;
  position: relative;
  z-index: 2;
}
.calendar-details .details-container .events-container .event-bar .event .event-name {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectedTextSpan {
  font-size: 14px;
}
.calendar-details .details-container .events-container {
  /* height: 512px; */
  min-height: 100px;
  overflow-y: auto;
}
.eos-calendar-details .details-container .events-container {
  /* height: 400px; */
  /* height: 100%; */
  overflow-y: auto;
}

.eos-searchBar {
  width: 93% !important;
  margin-inline: auto;
  margin-top: 10px !important;
}
.height-145 {
  height: 145vh !important;
}

.eos-searchBar.showFilterActive .form-group-wrapper .globalFilterIcon {
  color: var(--amplify-primary-color);
}
.eos-searchBar .form-group-wrapper .globalFilterIcon {
  position: absolute !important;
  right: 15px !important;
  top: 12px !important;
  font-size: 22px !important;
  color: var(--bs-gray-400);
}
.globalSearchBar.eos-searchBar .form-group-wrapper .globalSearch {
  position: absolute !important;
  right: 45px !important;
  top: 12px !important;
  font-size: 22px !important;
  color: var(--bs-gray-400);
}

.events.badge-colors {
  width: 100%;
  grid-column-gap: 5px;
  column-gap: 5px;
  justify-content: center;
  flex-wrap: wrap;
}
.events.badge-colors .event-dot {
  width: 8px;
  height: 8px;
}

.custom-toolbar {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.date-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.date-container span {
  font-size: 1.7vw;
}

.eos-testing-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg .date-container span {
  font-size: 1.3vw;
}

.height80 {
  height: 80vh !important;
}
.showZoneModal .modal-content {
  min-height: 90vh !important;
}

.testDoneZoneModal {
  z-index: 1100 !important;
}
.dateRangeFilter {
  border-radius: 5px;
  padding: 2px 10px;
  color: #212529;
  border: 1px solid #ddd;
  max-width: calc(100% - 149px);
  width: 100%;

  height: 40px;
}
.accordion-header {
  margin: 0 !important;
}

.modalLineHeaders {
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 0;
  color: var(--icon-gray-color);
}
.css-6j8wv5-Input {
  margin: 0 2px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.radioHead {
  display: flex;
}
.radioHead > * {
  flex-basis: 100%;
}

.roleSelector {
  margin: 0;
}

.buttonHeader {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 10px;
}

.headerButton {
  margin-right: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
  min-width: 130px;
  font-size: 1rem;
  border-radius: 0;
  font-weight: normal;
  /* text-align: start; */
  /* border: 1px solid yellow; */
}
.headerButton.btn-primary {
  background-color: var(--primary-btn-color);
  border-color: var(--primary-btn-color);
  color: var(--text-white);
}
.headerButton.btn-primary:hover,
.headerButton.btn-primary:active,
.headerButton.btn-primary:focus {
  background-color: var(--primary-btn-color);
  box-shadow: none;
}
.headerButton.btn-secondary {
  background-color: var(--text-black-color);
  border-color: var(--text-black-color);
  color: var(--text-white);
}
.headerButton.btn-secondary:hover,
.headerButton.btn-secondary:active,
.headerButton.btn-secondary:focus {
  background-color: var(--text-black-color);
  box-shadow: none;
}
a.downloadTest {
  color: #3472f7;
  text-decoration: none;
}

.filterFields {
  margin-top: 10px;
}

.filterDrop {
  width: 145px;
  margin-right: 10px;
}

.searchInput {
  max-width: 500px;
  padding-left: 10px;
  border-top: none !important;
  border-right: none !important;
}

.searchFilterButton {
  width: 130px;
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
  margin-bottom: 0 !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 4px;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 3px;
}
.switch input:checked + .slider {
  background-color: #08a003;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.switch input:disabled {
  cursor: default;
}
.switch .slider.round.disabled {
  cursor: default;
  opacity: 0.5;
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}

.changeRoleSelector {
  width: 175px;
}

.createButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.modalButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.headerNav {
  display: flex;
  justify-content: space-between;
}

.toggleButtonGroup {
  width: 100px;
}

.checkboxRow {
  display: flex;
  flex-direction: column;
}

.releaseCheckbox {
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.checkboxLabel {
  margin-top: 10px;
}

.createLabModal {
  min-width: 90vw !important;
  min-height: 90vh !important;
}

.createLabBody {
  display: flex;
  flex-wrap: wrap;
}

.labAddressInput {
  display: flex;
  flex-direction: column;
  width: 97%;
}

.notesInput {
  width: 97%;
}

.labModalInput {
  padding: 2px 10px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

.email {
  -webkit-box-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  border: 1px solid #ddd;
  min-height: 38px;
  padding: 2px 8px;
  border-bottom: 0px;
}

.email-input:hover,
.email-input:active,
.email-input:focus {
  border-color: var(--border-color);
}

.email-attachment-btn {
  line-height: 0;
  width: 10%;
  padding: 5px;
  font-size: x-large;
  text-align: center;
  position: relative;
}

.popOverAttachmentsOverlay {
  border-color: var(--bs-popover-border-color);
  /* border-color: #31465f; */
  width: auto;
  max-width: 1000px;
  max-height: 1000px;
  overflow-y: visible;
  overflow-x: visible;
  min-width: 500px;
}

.popOverAttachmentsOverlay > div {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.popOverAttachments {
  text-align: center;
  border-bottom: none;
  padding: 16px;
  background-color: var(--primary-btn-color);
  color: var(--text-white);
}

.badge-span {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  line-height: normal;
  right: 2px;
  top: 2px;
  border-radius: 50%;
  font-size: 14px;
  background-color: maroon;
  text-align: center;
  color: #ffffff;
}
.new-table-desgin .separate-border-tr {
  border-collapse: separate;
  border-spacing: 0 5px;
}
.trDataWrapper {
  background-color: #ffffff;
  border-bottom: 1px solid transparent !important;
}
.shows-screen-parent .table > thead > tr > th {
  font-weight: 500 !important;
  padding-bottom: 12px !important;
}
.shows-screen-wrapper .card-title > div {
  font-size: 18px;
  color: var(--text-black-color);
}

.shows-screen-wrapper .card-title > div span {
  color: #08a003;
  font-size: 18px;
  display: inline-block;
}
.shows-screen-parent .table > thead > tr > th {
  font-weight: 500 !important;
  padding-bottom: 12px !important;
}
/* .new-table-desgin .table tbody td:first-child,
.new-table-desgin .table thead th:first-child {
  padding-left: 8px !important;
} */

@media (max-width: 991px) {
  .sidebar {
    z-index: 21 !important;
  }
  .daterangepicker .drp-buttons {
    display: flex !important;
  }
  .popOverAttachmentsOverlay {
    min-width: 280px;
  }

  .badge-span {
    cursor: pointer;
    width: 15px;
    height: 15px;
    position: absolute;
    line-height: normal;
    right: 0px;
    top: 2px;
    border-radius: 50%;
    font-size: 10px;
    background-color: maroon;
    text-align: center;
    color: #ffffff;
  }
}

.email-attachment-bar {
  min-height: 50px;
  justify-content: space-between;
  border-radius: 0;
}

.email-attachment-bar span {
  margin-right: 15px;
}

.delete-attachment {
  padding: 5px;
  border-left: 1px solid #ddd;
}

.delete-attachment:hover {
  background-color: var(--bs-border-color);
  border-left-color: transparent;
  border-radius: 50%;
}

.delete-attachment:active {
  /* background-color: var(--bs-gray-700); */
}

.MessageInput {
  height: 140px;
  padding: 2px 10px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.MessageInput:hover,
.MessageInput:active,
.MessageInput:focus {
  border-color: var(--border-color);
}

.MessageInput:focus {
  box-shadow: none;
  outline: none;
}

.adminHomeTable td {
  border: none;
}

.adminHomeTable tr:hover {
  border: none;
}
.pdfView {
  flex: 1;
  width: 100%;
  height: 100%;
}

.pdfContainer {
  height: 700px;
}

.footerText {
  font-size: 9px;
  padding-right: 10px;
}

.truncate {
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-right {
  text-align: right;
}
.pr-0 {
  padding-right: 0;
}
.emailWidth {
  width: 20%;
}
.tdWidth9 {
  width: 9%;
}
.tdWidth10 {
  width: 10%;
}
.tdWidth12 {
  width: 12%;
}
.tdWidth14 {
  width: 14%;
}
.tdWidth16 {
  width: 16%;
}
.tdWidth18 {
  width: 18%;
}
.tdWidth45 {
  width: 45%;
}
.vaccination-status-name {
  font-size: 12px !important;
  margin-bottom: 5px !important;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  min-height: 18px;
}
.vaccination-status {
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  min-height: 38px;
  display: inline-block;
}
.form-group-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
}
.form-group-wrapper > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
  margin-top: 0;
  display: inline-block;
  font-size: 14px;
  color: var(--icon-gray-color);
}
.form-group-wrapper > input {
  flex: 0 0 calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
  width: calc(100% - 20% - 15px);
  font-size: 14px;
}
.form-group-wrapper .roleSelector {
  width: 100%;
}
.form-group-wrapper.activeEmploye .roleSelector {
  width: 50%;
}
.form-group-wrapper.activeEmploye .siteSelector {
  width: 50%;
}
.form-group-wrapper.activeEmploye .siteSelector .css-14el2xx-placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.roleSelector .css-1s2u09g-control,
.siteSelector .css-1s2u09g-control {
  background-color: #f9f9f9;
  border-color: #ddd !important;
}
.roleSelector .css-1s2u09g-control:hover,
.siteSelector .css-1s2u09g-control:hover {
  border-color: #ddd !important;
}
/*.roleSelector .css-qc6sy-singleValue, .siteSelector .css-qc6sy-singleValue  {*/
/*  color: #888888;*/
/*}*/
/*.roleSelector .css-1pahdxg-control .css-qc6sy-singleValue {*/
/*  color: #000000 !important;*/
/*}*/

.css-lw9j89e-menu {
  z-index: 1000;
}

.filterInputBar .css-1pahdxg-control {
  max-height: fit-content !important;
}

.createClientsWrapper.test-view-profile {
  flex-wrap: wrap;
}
.createClientsWrapper {
  display: flex;
  column-gap: 30px;
}
.createClientsWrapper .leftSide {
  flex: 0 0 calc(40% - 15px);
  max-width: calc(40% - 15px);
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;
}

.createClientsWrapper .rightSide {
  flex: 0 0 calc(60% - 15px);
  max-width: calc(60% - 15px);
  width: 100%;
}

.createClientsWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  line-height: 1;
  display: block;
}
.createClientsWrapper .first-last-name-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 30px;
}
.createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
}
.createClientsWrapper .form-group-wrapper > label {
  color: var(--icon-gray-color);
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 5px;
}
.createClientsWrapper .form-group-wrapper > label,
.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
}
.reminderInputField {
  flex: 0 0 100%;
  max-width: 95%;
  width: 95%;
  font-size: 14px;
}
.createClientsWrapper .form-group-wrapper .fa-search {
  position: absolute;
  right: 10px;
  top: 36px;
}
.createClientsWrapper .form-group-wrapper .globalSearch {
  position: absolute !important;
  right: 15px !important;
  top: 12px !important;
  font-size: 22px !important;
  color: var(--bs-gray-400);
}
.globalSearchBar.eos-searchBar .form-group-wrapper .cancelSearch {
  position: absolute !important;
  right: 70px !important;
  top: 15px !important;
  font-size: 16px !important;
}
.createClientsWrapper .form-group-wrapper .cancelSearch {
  position: absolute !important;
  right: 50px !important;
  top: 15px !important;
  font-size: 16px !important;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px;
}
.inputFileWrapper {
  flex: 0 0 120px;
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.inputFileWrapper > input {
  opacity: 0;
  width: 100%;
  height: 100%;
}
.inputFileWrapper > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.positionRelative {
  position: relative;
}
.profileEditImage {
  position: absolute;
  bottom: 10px;
  right: -5px;
  background-color: #f5f5f5;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.checkBoxWrapper {
  display: flex;
  align-items: center;
}
.checkBoxWrapper span {
  flex: 0 0 60%;
  max-width: 60%;
  width: 100%;
}
.checkBoxWrapper input {
  flex: 0 0 auto;
}
.showsModal .checkBoxWrapper {
  width: 100%;
  column-gap: 15px;
}
.showsModal div .checkBoxWrapper span {
  flex: 0 0 42%;
  max-width: 42%;
  width: 100%;
}
.showsModal div .checkBoxWrapper > div {
  flex: 0 0 auto;
}
:root {
  --amplify-primary-color: rgb(168, 38, 50);
  --amplify-primary-tint: #31465f;
  --amplify-primary-shade: rgb(168, 38, 50);
}
.dashboard-page {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
}
.dashboard-tab {
  flex: 0 0 calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.dashboard-tab h4 {
  font-size: 24px;
  margin-top: 0;
  color: #565656;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 30px 15px 15px;
}
.dashboard-tab p {
  font-size: 24px;
  color: #a82632;
  margin: 0;
  padding: 0 15px 30px;
}
.labsModalWrapper {
  display: flex;
  column-gap: 15px;
}
.labsModalWrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 7px);
  max-width: calc(50% - 7px);
}
.labsModalWrapper .form-group-wrapper table > tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}
.labsModalWrapper .form-group-wrapper table thead tr,
.labsModalWrapper .form-group-wrapper table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
#example-collapse-text {
  position: absolute;
  width: 96%;
}

.labelYesNo {
  text-decoration: underline;
  font-weight: bold;
  min-width: 35px;
  margin-right: 8px;
  display: inline-block;
}
.formWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  padding-left: 15px;
}
.selectTestWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.imageWrapper {
  min-height: 150px;
}
.text-grey {
  color: grey !important;
}
.uploadFilesArea {
  margin-top: 36px;
}
.linkedText {
  color: #3472f7 !important;
  cursor: pointer;
}
.tdWidth10 {
  width: 10%;
}
.tdWidth12 {
  width: 12%;
}
.showDisclaimerText {
  margin-left: 5px;
  color: #000 !important;
}
.showDisclaimerText span {
  color: #3472f7;
  cursor: pointer;
}
.scroll-popOver {
  max-height: 400px;
  overflow-y: auto;
}
.filterResetBtn {
  display: flex;
  justify-content: right;
  margin-bottom: -15px;
  margin-top: 10px;
}
.textUnderLine {
  text-decoration: underline;
}

.btn:disabled {
  color: gray;
  /*border-color: gray;*/
  background-color: var(--text-black-color);
}
.btn-warning {
  background-color: rgb(168, 38, 50) !important;
  border-color: rgb(168, 38, 50) !important;
}

.loginWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
}
.login-left-side {
  background-color: #f7f7f8;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input-group.group-wrapper {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 10px;
}
.group-wrapper {
  margin-bottom: 30px;
}
.input-group.group-wrapper input,
.input-group.group-wrapper span {
  border: none;
}

.group-wrapper > span.input-group-text {
  padding: 10px 15px;
  background-color: transparent;
}
.group-wrapper > span.input-group-text i {
  color: var(--icon-gray-color);
}
.option label {
  font-size: 85%;
  margin-bottom: 0;
  text-transform: none !important;
  color: hsl(0, 0%, 20%);
}
.searchList .modal-content {
  border-color: var(--amplify-primary-tint) !important;
}
.searchList .modal-header {
  background-color: var(--amplify-primary-tint) !important;
}
.login-right-side {
  flex: 0 0 50%;
  width: 100%;
  padding-inline: 1rem;
  max-width: 440px;
  margin-inline: auto;
}
.login-right-side .tag-line {
  font-size: 14px;
  font-weight: normal;
  color: var(--light-gray-color);
}
.loginWrapper h3 {
  font-weight: 600;
}
.loginWrapper .logo-width {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}
.loginWrapper input {
  font-size: 14px;
  height: 50px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding: 1rem;
  border-radius: 10px;
}
.loginWrapper input::placeholder {
  color: rgb(130, 130, 130);
}
.loginWrapper label {
  font-size: 14px;
  font-weight: 400;
}
.input-group.group-wrapper:hover,
.input-group.group-wrapper:active,
.input-group.group-wrapper:focus {
  border: 1px solid rgb(168, 38, 50) !important;
}
.loginWrapper a.resetPassword {
  text-decoration: none;
  font-weight: 400;
}
.loginWrapper a.resetPassword:hover {
  text-decoration: underline;
  color: rgb(49, 70, 95);
}
.loginWrapper .signin-btn {
  width: 100% !important;
  max-width: 180px;
  height: 50px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: #a82632;
  border-width: 1px;
  border: 1px solid #a82632;
  border-radius: 0;
  opacity: 1;
}
.loginWrapper .signin-btn:disabled {
  opacity: 0.8;
  background-color: #a82632 !important;
  border-color: #a82632;
}
.uploadFileHippa {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fileNameHippa {
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #cccccc;
  padding: 0 10px;
}
.blinkText {
  position: absolute;
  top: 50px;
  right: 50px;
  color: red;
  font-weight: bold;
}
.pdfDownLink {
  text-decoration: none;
  color: #3472f7 !important;
}
.pdfDownLink:hover,
.pdfDownLink:active {
  text-decoration: none !important;
  color: #3472f7 !important;
}
.marginTop0 {
  margin-top: 0px !important;
}

/*custom-checkbox*/
.checkbox-custom {
  opacity: 0;
  position: absolute;
}
.results-sent-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.checkbox-custom,
.checkbox-custom-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 15px;
  padding: 2px;
  margin-right: 0;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "✓";
  font-family: "FontAwesome";
  background: green;
  color: #fff;
}

.checkbox-custom,
.checkbox-custom-label-yellow {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-custom-label-yellow,
.radio-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label-yellow:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 15px;
  padding: 2px;
  margin-right: 0;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label-yellow:before {
  content: "✓";
  font-family: "FontAwesome";
  background: rgba(255, 251, 0, 0.836);
  color: #000;
}

.EditStyle {
  border: 1px solid #b6b4b4;
  border-radius: 5px;
  padding: 2px 10px;
  min-height: 36px;
}
.radioButton {
  padding: 5px;
}
.form-group-wrapper .scheduleView {
  flex: 0 0 calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
}
.form-group-wrapper .scheduleView > input {
  min-height: 45px;
  font-size: 14px;
  background-color: transparent;
  border: 0;
}
.scheduleLabel {
  cursor: pointer;
  color: var(--bs-gray-700);
}
.scheduleModal .modal-content {
  min-height: 700px;
}
.dayLable {
  font-weight: 500;
  cursor: pointer;
}
.scheduleView {
  border: 1px solid var(--border-color);
  border-radius: 5px;
}
.scheduleView .scheduleView-inner .weekDays-wrapper,
.scheduleView .scheduleView-inner .schedule-location {
  background-color: var(--gray-secondary-color);
  height: 47px;
  border-radius: 5px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*.scheduleView .scheduleView-inner .weekDays-wrapper {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/
.scheduleView .scheduleView-inner .schedule-location {
  border-radius: 0 5px 0 0;
}
.scheduleView .scheduleView-inner .schedule-test-type {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scheduleView .schedules-header {
  border-bottom: 1px solid var(--border-color);
  margin-inline: 0;
  margin-bottom: 25px;
}
.scheduleView .schedules-header > .col-md-3 {
  padding-left: 0;
}
.scheduleView .schedules-header > .col-md-5 {
  padding-right: 0;
}
.sideView > Select {
  cursor: pointer;
  border: 1px solid var(--bs-gray-300);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.listItems:hover {
  background-color: var(--bs-gray-300);
}
.SelectorQuestionGroup {
  margin-top: 30px;
}

.radioBox-wrapper {
  display: flex;
  align-items: center;
  min-width: 50px;
}
.bodyScroll {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}
textarea.focus-none:focus {
  outline: none;
}
HEAD .modalButtons.ml-1 {
  margin-left: 8px;
}
.personalizationCheckbox input {
  width: 20px;
  height: 20px;
  accent-color: var(--btn-danger);
}
.personalizationCheckbox label {
  font-size: 18px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.graphWrapper {
  display: flex;
  justify-content: center;
}
.graphWrapper canvas {
  height: 180px !important;
  width: 180px !important;
}
.selectDateTime > input {
  max-width: 100% !important;
}
/*.assignSchedule {*/
/*  min-width: 250px;*/
/*  max-width: 400px;*/
/*}*/

.testTypeAction {
  cursor: pointer;
}
.testTypeAction:hover {
  color: var(--amplify-primary-color);
}
.bs-popover-start > .popover-arrow {
  right: -34px !important;
}

.popOverAttachmentsOverlay > .popover-arrow {
  right: -9px !important;
}

.popOverSchedule {
  border-color: #31465f;
  width: auto;
  max-width: 700px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
.assignSchedule .css-xb97g8:hover {
  background-color: lightblue;
  color: blue;
}
.nav-disabled {
  background: rgba(255, 255, 255, 0.13);
  opacity: 0.4 !important;
}
.barGraph canvas {
  height: 280px !important;
  width: 100% !important;
}
.barGraph {
  height: 100%;
}

.listView {
  margin-left: 2px;
  padding: 0px;
}
.listView li {
  font-size: 20px;
}

.listView li span {
  color: grey;
  text-decoration: underline;
}
.colorBox {
  padding: 0 10px 0 9px;
  margin-right: 10px;
}
.filterSelector {
  max-width: calc(100% - 149px);
  width: 100%;
}
.filterInputBar .css-26l3qy-menu,
.filterSelector .css-26l3qy-menu {
  z-index: 999;
}

.simpleInputBar:focus {
  border-left: 1px solid var(--border-color) !important;
}

.column-gap-10 {
  column-gap: 10px;
}
.pendingReleaseTable .table.employeeTableWrapper > thead > tr > th:first-child,
.pendingReleaseTable .table.employeeTableWrapper > tbody > tr > td:first-child {
  min-width: 60px;
  width: 60px;
}
.pendingReleaseTable .table.progrmaTableWrapper > thead > tr > th:first-child,
.pendingReleaseTable .table.progrmaTableWrapper > tbody > tr > td:first-child {
  min-width: 66px;
  width: 66px;
}
.pendingReleaseTable .table.programsViewTable > tbody > tr > td:last-child {
  padding-right: 8px !important;
}
.card .table tbody td:last-child .accordion-button {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  color: var(--text-black-color) !important;
}
.accordion-button:not(.collapsed) {
  background-color: var(--main-body-bg) !important;
}
.accordion-button:focus {
  border-color: var(--border-color) !important;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("assets/img/arrow-up.png") !important;
  transform: rotate(-360deg);
}

.modalPhoneInput {
  width: 100%;
}
.form-group-wrapper .react-date-picker__wrapper {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  min-height: 45px;
}
.form-group-wrapper.callTimeReminderWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form-group-wrapper.callTimeReminderWrapper .form-group-wrapper .modalLineHeaders {
  text-transform: none;
}
.form-group-wrapper.callTimeReminderWrapper .callTimeRemRadioGroup .radio-button-wrapper label {
  text-transform: capitalize;
}
.react-date-picker__inputGroup {
  font-size: 14px;
}
.react-date-picker__button svg {
  stroke: var(--text-black);
  width: 16px;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: var(--text-black) !important;
}

.card .card-body {
  padding: 15px 10px 0;
}
.card .card-body .table {
  margin-bottom: 0;
  border: 1px solid transparent;
}
.overFlow-y-hidden {
  overflow-y: hidden;
}
.pac-container {
  z-index: 1100 !important;
}
.rbc-overlay {
  z-index: 1100 !important;
}
.colorRed {
  color: red;
}
.colorGreen {
  color: green;
}
.secondModal {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1056;
}

.updateConfirmation {
  z-index: 1057;
}
.inconsistant-radio-wrapper {
  display: flex;
  column-gap: 10px;
}
.inconsistant-radio-wrapper .form-check {
  display: flex;
  align-items: center;
}
.inconsistant-radio-wrapper .form-check label {
  color: #212529;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  top: 2px;
}
/* .programsViewTable tr td *{
  opacity: 1;
} */
.programSchedules td {
  border: 1px solid gray;
}

.ProgramTestTypeTable tbody tr:hover {
  border: 0 !important;
  border-bottom: 1px solid transparent !important;
}
.testTypeTable {
  width: 480px;
  padding: 0;
}
.testTypeTable thead,
.testTypeTable tbody {
  display: table;
  width: 100%;
}
.testTypeTable th {
  padding: 3px !important;
  color: var(--text-black-color) !important;
  font-weight: bold !important;
}
.testTypeTable td {
  border: 1px solid lightgray;
  padding: 3px !important;
}
.testTypeTable .table > :not(:first-child) {
  border-top: 0;
}
.testTypeTable thead tr {
  border: 1px solid lightgray;
  border-bottom: 1px solid transparent;
}
.testTypeTable thead tr:hover {
  border: 1px solid lightgray;
  border-bottom: 1px solid transparent;
}
.subTableRow:hover {
  background-color: #fff !important;
  border: 1px solid transparent;
  border-color: transparent !important ;
}
.expended-table-wrapper {
  padding-right: 10px !important;
  padding: 10px;
  padding-left: 10px !important;
}
.striped-table-card {
  margin: 0;
}
.expandedTable tbody tr:nth-child(odd) {
  background-color: beige !important;
}
.historyViewModal .modal-content {
  min-height: 777px;
}

.historyViewModal .pdfModalBody {
  padding: 0 !important;
}

/* .expandedTable tbody tr:nth-child(even):hover {
  background-color: beige !important;
} */
.column-gap-15 {
  column-gap: 15px;
}
.assignProgram-modal-wrapper > label,
.assignProgram-inner > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
}
.assignProgram-inner > form {
  flex: 0 0 80%;
  max-width: 80%;
  width: 100%;
}
.assignProgram-inner .question-wrapper {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
}
.assignProgram-inner .questionGroup-wrapper {
  flex: 0 0 80%;
  max-width: 80%;
  width: 100%;
  column-gap: 15px;
}
.assignProgram-inner .questionGroup-wrapper > div {
  flex: 0 0 calc(50% - 7.5px);
  max-width: calc(50% - 7.5px);
  width: 100%;
}
.w-30 {
  width: 30%;
}
.w-65 {
  width: 65%;
}
.ml-auto {
  margin-left: auto;
}
.message-label {
  position: relative;
  top: 45px;
}
.inputFieldMessage .css-1s2u09g-control {
  border-bottom: 0;
  border-color: #ddd !important;
}
.programColor {
  display: inline-block;
  vertical-align: initial;
}
.programSchedules td {
  width: 175px !important;
}

.mainLogo-wrapper {
  max-width: 250px;
  margin: auto;
}
.qrWrapper {
  box-shadow: none;
  position: relative;
  padding-inline: 10px;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.registered-successful h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 16px;
}
.card-name {
  padding-bottom: 45px;
}
.registered-inner {
  padding: 15px 0;
}
.userIcon {
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.qrWrapper .h4 {
  padding-top: 40px;
  margin-bottom: 0;
}
.qrWrapper .h4 > h4 {
  font-size: 16px;
}
.qrCode {
  width: 185px !important;
  height: 185px !important;
}

#section-to-print,
.createClientsWrapper .printSide {
  display: none;
  flex: 0 0 100%;
  max-width: 100%;
  /*flex: 0 0 520px;*/
  /*max-width: 520px;*/
  width: 100%;
  padding: 15px;
  background-color: #fff;
}

.registered-successful .printSide {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
  /*flex: 0 0 520px;*/
  /*max-width: 520px;*/
  width: 100%;
  padding: 15px;
  background-color: #fff;
}
.newListingIcons {
  display: inline-block;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.login-right-side .modalPhoneInput.PhoneInput {
  border: 1px solid var(--border-color);
  padding: 0 0 0 5px;
  border-radius: 10px;
}
.login-right-side .modalPhoneInput.PhoneInput:hover,
.login-right-side .modalPhoneInput.PhoneInput:active,
.login-right-side .modalPhoneInput.PhoneInput:focus {
  border-color: #a82632;
}
.login-right-side .modalPhoneInput.PhoneInput input {
  border: 0;
}
.form-group-wrapper.phoneInput input {
  border-radius: 5px;
  border: 1px solid var(--border-color);
}
.form-group-wrapper .react-date-picker__wrapper {
  border: 1px solid var(--border-color);
  border-radius: 5px;
}
/*react tabs styling*/
.system-settings-wrapper .react-tabs__tab-list {
  border-bottom: 0;
  margin-bottom: 0;
}
.system-settings-wrapper .react-tabs__tab--selected {
  border-radius: 10px 10px 0 0;
}
.system-settings-wrapper .react-tabs__tab {
  padding: 6px 12px 16px;
  font-size: 14px;
}

.resizeHorizontal {
  overflow: auto;
  resize: horizontal;
  /* min-width: inherit; */
  min-width: auto;
}

/*country phone input selection*/
.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput {
  border: 1px solid var(--border-color);
  min-height: 45px;
  background-color: var(--text-white);
  border-radius: 5px;
  padding-inline: 15px;
  position: relative;
}
.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput input {
  border: 0;
  font-size: 14px;
  min-height: 100%;
}
/*select dropdown*/
.filterInputBar.dateRangeFilter.filerInputField,
.css-1s2u09g-control,
.css-1pahdxg-control {
  min-height: 45px !important;
  box-shadow: none !important;
  border-color: var(--border-color) !important;
}
.css-1s2u09g-control:hover,
.css-1s2u09g-control:active,
.css-1s2u09g-control:focus,
.css-1pahdxg-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: var(--border-color) !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-tj5bde-Svg {
  color: var(--text-black);
  width: 18px;
}

.pre-register-comp .css-1s2u09g-control {
  min-height: 40px !important;
}
.pre-register-comp .css-qc6sy-singleValue {
  color: var(--icon-gray-color);
}

/*radio button style*/

.radio-button-wrapper input[type="radio"] {
  display: none;
}

.radio-button-wrapper label {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
}

.isPositiveResult > td {
  background-color: #fff0f0 !important;
  color: #ff2f2f !important;
}

.radio-button-wrapper label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 500ms ease;
}
.radio-button-wrapper input[type="radio"]:checked + label::before {
  border-color: var(--btn-danger);
  background-color: var(--btn-danger);
  transition: all 0.5s ease-in;
}
.radio-button-wrapper.colorBlack input[type="radio"]:checked + label::before {
  border-color: #000000;
  background-color: #000000;
}
.itemRowWrapper .css-14el2xx-placeholder {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.registerFormWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}
.preLoginWrapper {
  max-width: 560px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 15px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  width: 100%;
  height: auto;
}
.registerWrapper .logo-width {
  width: 200px;
}
.preLoginWrapper .logo-width {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}
.preLoginWrapper input {
  font-size: 14px;
  height: 50px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding: 1rem;
  border-radius: 10px;
}
.preLoginWrapper input::placeholder {
  color: rgb(130, 130, 130);
}
.preLoginWrapper label {
  font-size: 14px;
  font-weight: 400;
}
.codeScannerPhoneInput {
  min-height: auto !important;
}
.tcMatrixPlusIcon {
  font-size: 30px;
  color: var(--icon-gray-color);
  -webkit-text-stroke: 1px white;
}
.tcMatrixPlusIcon:hover {
  color: var(--btn-danger);
}

.modal-2xl {
  max-width: 99%;
}

.modal-xxl {
  max-width: 75%;
}

.activeProgram {
  color: var(--btn-danger);
}
.tcMatrixRows .departmentName,
.matrixRowsProgram,
.programName {
  color: var(--light-gray-color);
  display: inline-block;
  font-weight: normal;
}

.tcMatrixWrapper table th,
.tcMatrixZone table th {
  /* border-collapse: collapse; */
  /* border-collapse: collapse; */
  border: 1px solid var(--light-gray-color);
}
.tcMatrixRows,
.matrixRowsProgram {
  border-bottom: 0 !important;
}
.tcMatrixRows > td {
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
  display: block;
  width: 100% !important;
  max-width: calc(100% - 20px) !important;
  margin-left: 10px;
  margin-bottom: 15px;
  margin-right: 10px;
  border-radius: 10px;
  border-bottom: 1px solid transparent;
}
.tcMatrixWrapper .tcMatrixViewCard {
  border: 0 !important;
}
.matrixRowsProgram {
  margin-bottom: 15px;
}

/* .activeDepartment {
  background-color: rgb(234, 227, 227) !important;
} */
.matrixRowsProgram > td {
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
  display: block;
  width: 100% !important;
  max-width: calc(100% - 20px) !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  border-bottom: 1px solid transparent;
}
.matrixListData,
.matrixListData > td {
  border-bottom: 0 !important;
}

.badges-button-wrapper {
  margin-top: 22px;
}
.badges-button-wrapper .headerButton.btn-secondary {
  display: block;
}
.badges-button-wrapper .print-qr-button {
  position: relative;
  top: 43px;
}
.pendingReleaseTable.tcMatrixWrapper .table > thead > tr > th:first-child,
.pendingReleaseTable.tcMatrixWrapper .table > tbody > tr > td:first-child {
  max-width: 140px !important;
  width: 140px !important;
  padding-inline: 5px;
}
.tcMatrixZone,
.tcMatrixWrapper {
  overflow-x: auto;
  max-height: 90vh;
}

.tcMatrixZone .tcMatrixTHead {
  position: sticky;
  top: -1px;
  background: white;
  z-index: 20;
}

.tcMatrixZone::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.tcMatrixZone::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.tcMatrixZone::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 2px solid #555555;
}

/* .tcMatrixZone .table > thead > tr > th,
.tcMatrixZone .table > tbody > tr > td, */
.tcMatrixZone .table > thead > tr > .secondTableBlock,
.tcMatrixZone .table > tbody > tr > .secondTableBlock,
.tcMatrixWrapper .table > thead > tr > th,
.tcMatrixWrapper .table > tbody > tr > td {
  min-width: 120px;
  position: relative;
}
.tcMatrixTableCell.secondTableBlock {
  min-width: 120px;
}
.tcMatrixTableCell.centered {
  text-align: center !important;
}
.tcMatrixWrapper .table > tbody > tr > td,
.tcMatrixZone .table > tbody > tr > td {
  border-bottom: 0;
}
.tcMatrixWrapper .table > tbody > tr > td.bg-color-td,
.tcMatrixZone .table > tbody > tr > td.bg-color-td {
  height: 33px !important;
  min-height: 33px !important;
}
.tcMatrixWrapper .table > thead > tr > th,
.tcMatrixZone .table > tbody > tr > td {
  padding-left: 8px !important;
}

.tcMatrixTable .tcMatrixTHead .tcMatrixTableCell:last-child {
  border-right: 0 !important;
}

.tcMatrixTable .tcMatrixTableRow .tcMatrixTableCell:last-child {
  border-right: 1px solid #ddd;
}

/* .departmentLeftSideTable > thead > tr > th:nth-child(1),
.departmentLeftSideTable > thead > tr > th:nth-child(2),
.departmentLeftSideTable > thead > tr > th:nth-child(3),
.departmentLeftSideTable > thead > tr > th:nth-child(4),
.departmentLeftSideTable > thead > tr > th:nth-child(5),
.departmentLeftSideTable > thead > tr > th:nth-child(6),
.departmentLeftSideTable > thead > tr > th:nth-child(7),
.zoneLeftSideTable > thead > tr > th:nth-child(1),
.zoneLeftSideTable > thead > tr > th:nth-child(2),
.zoneLeftSideTable > thead > tr > th:nth-child(3),
.zoneLeftSideTable > thead > tr > th:nth-child(4),
.zoneLeftSideTable > thead > tr > th:nth-child(5),
.zoneLeftSideTable > thead > tr > th:nth-child(6),
.zoneLeftSideTable > thead > tr > th:nth-child(7),
.zoneLeftSideTable > thead > tr > th:nth-child(8) */

.departmentLeftSideTable > thead > tr > th:nth-child(4),
.departmentLeftSideTable > tbody > tr > td:nth-child(4) {
  z-index: 6 !important;
}

.departmentLeftSideTable > thead > tr > th:nth-child(5),
.departmentLeftSideTable > tbody > tr > td:nth-child(5) {
  z-index: 5 !important;
}

.departmentLeftSideTable > thead > tr > th:nth-child(6),
.departmentLeftSideTable > tbody > tr > td:nth-child(6) {
  z-index: 4 !important;
}

.departmentLeftSideTable > thead > tr > th:nth-child(7),
.departmentLeftSideTable > tbody > tr > td:nth-child(7) {
  z-index: 3 !important;
}

.zoneLeftSideTable > thead > tr > th:nth-child(4),
.zoneLeftSideTable > tbody > tr > td:nth-child(4) {
  z-index: 7 !important;
}

.zoneLeftSideTable > thead > tr > th:nth-child(5),
.zoneLeftSideTable > tbody > tr > td:nth-child(5) {
  z-index: 6 !important;
}

.zoneLeftSideTable > thead > tr > th:nth-child(6),
.zoneLeftSideTable > tbody > tr > td:nth-child(6) {
  z-index: 5 !important;
}

.zoneLeftSideTable > thead > tr > th:nth-child(7),
.zoneLeftSideTable > tbody > tr > td:nth-child(7) {
  z-index: 4 !important;
}

.zoneLeftSideTable > thead > tr > th:nth-child(8),
.zoneLeftSideTable > tbody > tr > td:nth-child(8) {
  z-index: 3 !important;
}

.tcMatrixWrapper .table > thead > tr > th:nth-child(3),
.tcMatrixWrapper .table > tbody > tr > td:nth-child(3),
.tcMatrixWrapper .table > thead > tr > th:nth-child(4),
.tcMatrixWrapper .table > tbody > tr > td:nth-child(4),
.tcMatrixWrapper .table > thead > tr > th:nth-child(5),
.tcMatrixWrapper .table > tbody > tr > td:nth-child(5),
.tcMatrixWrapper .table > thead > tr > th:nth-child(6),
.tcMatrixWrapper .table > tbody > tr > td:nth-child(6),
/*.tcMatrixZone .table > thead > tr > th:nth-child(3),*/
/*.tcMatrixZone .table > tbody > tr > td:nth-child(3),*/
/*.tcMatrixZone .table > thead > tr > th:nth-child(4),*/
/*.tcMatrixZone .table > tbody > tr > td:nth-child(4),*/
/*.tcMatrixZone .table > thead > tr > th:nth-child(5),*/
/*.tcMatrixZone .table > tbody > tr > td:nth-child(5),*/
.tcMatrixZone .table > thead > tr > th:nth-child(6),
.tcMatrixZone .table > tbody > tr > td:nth-child(6) {
  /*width: 100% !important;*/
  padding-left: 5px;
  padding-right: 5px;
}

.tcMatrixZone .table .first-col-checkBox,
.tcMatrixWrapper .table .first-col-checkBox {
  min-width: 45px !important;
  /* max-width: 45px !important; */
  width: 45px !important;
  text-align: center;
}
.tcMatrixZone .badge-colors-wrapper {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  display: flex;
  column-gap: 5px;
  justify-content: center;
}
.tcMatrixZone .badge-gray,
.tcMatrixZone .badge-green,
.tcMatrixZone .badge-red,
.tcMatrixZone .badge-blue {
  width: 16px;
  height: 4px;
  background-color: #d7d4d4;
  border-radius: 1px;
}
.tcMatrixZone .badge-green {
  background-color: green;
}
.tcMatrixZone .badge-red {
  background-color: rgb(168, 38, 50);
}
.tcMatrixZone .badge-blue {
  background-color: blue;
}
.tcMatrixZone .checked-mark {
  /*background-color: #d7d4d4;*/
  /*width: 18px;*/
  /*height: 18px;*/
  /*text-align: center;*/
  /*color: #ffffff;*/
  /*font-size: 10px;*/
  top: 3px;
  position: absolute;
  right: 3px;
  /*border-width: 1px;*/
  /*border-style: solid;*/
  /*display: inline-flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
}
/*.tcMatrixZone .checked-mark span {*/
/*  line-height: 1;*/
/*}*/
/*.tcMatrixZone .checked-mark.green {*/
/*  background-color: green;*/
/*}*/
/*.tcMatrixZone .checked-mark.red {*/
/*  background-color: rgb(168, 38, 50);*/
/*}*/
/*.tcMatrixZone .checked-mark.blue {*/
/*  background-color: blue;*/
/*}*/

.tcMatrixZone .table .assignBlock {
  /* border: 1px solid red !important; */
}

.tcMatrixZone .table > thead > tr > .nameBlock,
.tcMatrixZone .table > tbody > tr > .nameBlock {
  /* border: 1px solid red; */
  min-width: 75px;
  /* width: 75px; */
}

.tcMatrixZone .table > thead > tr > .emailPhoneBlock,
.tcMatrixZone .table > tbody > tr > .emailPhoneBlock {
  /* border: 1px solid yellow; */
}

.tcMatrixZone .table > tbody > tr > .emailPhoneBlock > .tcMatrixPhoneCell {
  border-right: 1px solid var(--border-color);
  display: flex;
  height: 100%;
  align-items: center;
}

.tcMatrixZone .table > thead > tr > .tcMatrixResizeableCell,
.tcMatrixZone .table > tbody > tr > .tcMatrixResizeableCell {
  position: sticky;
}

.ques-Calendar {
  position: absolute;
  bottom: 3px;
}

.zoneRow td::before {
  bottom: 0px !important;
}

.tcMatrixZone .checked-Ques-mark {
  left: 3px;
  position: absolute;
  top: 3px;
}
/* .tcMatrixZone .tcMatrixCheckBox,
.tcMatrixWrapper .tcMatrixCheckBox {
  position: absolute;
  left: 15px;
  top: 24px;
} */
/* .tcMatrixTable .tcMatrixTableHead .tcMatrixTableCell .tcMatrixCheckBox {
  position: absolute;
  left: 8px;
  top: -15px;
} */
.tcMatrixZone .tcMatrixCheckBox.allCheck,
.tcMatrixWrapper .tcMatrixCheckBox.allCheck {
  top: 10px;
}

.tcMatrixZone .tcMatrixCheckBox.programName,
.tcMatrixWrapper .tcMatrixCheckBox.programName {
  top: 5px;
}
.tcMatrixZone .tcMatrixCheckBox.headerFirstCheckBox,
.tcMatrixWrapper .tcMatrixCheckBox.headerFirstCheckBox {
  top: 10px;
}
.tcMatrixZone .tcMatrixCheckBox > input,
.tcMatrixWrapper .tcMatrixCheckBox > input {
  width: 1rem;
  height: 1rem;
}
.tcMatrixZone .actions-icons {
  position: absolute;
  top: 2px;
  right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
}
.actions-icons .exclamation-mark,
.action-exclamation .exclamation-mark,
.header-left .exclamation-mark {
  border-radius: 50%;
  border: 1px solid blue;
  width: 20px;
  height: 20px;
  font-size: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: rgb(83 83 232);
}

/* .zoneLeftSideTable,
.departmentLeftSideTable {
  border-spacing: 0px;
  border-collapse: collapse;
} */

.zoneLeftSideTable > tbody > tr > td:nth-child(1),
.zoneLeftSideTable > thead > tr > th:nth-child(1) {
  position: sticky;
  left: 0;
}
.zoneLeftSideTable > tbody > tr > td:nth-child(2),
.zoneLeftSideTable > thead > tr > th:nth-child(2) {
  position: sticky;
  left: 45px;
}
.zoneLeftSideTable > tbody > tr > td:nth-child(3),
.zoneLeftSideTable > thead > tr > th:nth-child(3) {
  position: sticky;
  left: 90px;
}
/* .zoneLeftSideTable > tbody > tr > td:nth-child(4),
.zoneLeftSideTable > thead > tr > th:nth-child(4) {
  position: sticky;
  left: 135px;
}
.zoneLeftSideTable > tbody > tr > td:nth-child(5),
.zoneLeftSideTable > thead > tr > th:nth-child(5) {
  position: sticky;
  left: 205px;
}
.zoneLeftSideTable > tbody > tr > td:nth-child(6),
.zoneLeftSideTable > thead > tr > th:nth-child(6) {
  position: sticky; */
/* left: 325px; */
/* left: 280px;
}
.zoneLeftSideTable > tbody > tr > td:nth-child(7),
.zoneLeftSideTable > thead > tr > th:nth-child(7) {
  position: sticky; */
/* left: 445px; */
/* left: 355px;
}
.zoneLeftSideTable > tbody > tr > td:nth-child(8),
.zoneLeftSideTable > thead > tr > th:nth-child(8) {
  position: sticky; */
/* left: 643px; */
/* left: 505px;
} */

.departmentLeftSideTable > thead > tr > th:nth-child(1),
.departmentLeftSideTable > thead > tr > th:nth-child(2),
.departmentLeftSideTable > thead > tr > th:nth-child(3),
.departmentLeftSideTable > thead > tr > th:nth-child(4),
.departmentLeftSideTable > thead > tr > th:nth-child(5),
.departmentLeftSideTable > thead > tr > th:nth-child(6),
.departmentLeftSideTable > thead > tr > th:nth-child(7),
.zoneLeftSideTable > thead > tr > th:nth-child(1),
.zoneLeftSideTable > thead > tr > th:nth-child(2),
.zoneLeftSideTable > thead > tr > th:nth-child(3),
.zoneLeftSideTable > thead > tr > th:nth-child(4),
.zoneLeftSideTable > thead > tr > th:nth-child(5),
.zoneLeftSideTable > thead > tr > th:nth-child(6),
.zoneLeftSideTable > thead > tr > th:nth-child(7),
.zoneLeftSideTable > thead > tr > th:nth-child(8) {
  background-color: white;
  z-index: 2;
}

.departmentLeftSideTable > tbody > tr > td:nth-child(1),
.departmentLeftSideTable > tbody > tr > td:nth-child(2),
.departmentLeftSideTable > tbody > tr > td:nth-child(3),
.departmentLeftSideTable > tbody > tr > td:nth-child(4),
.departmentLeftSideTable > tbody > tr > td:nth-child(5),
.departmentLeftSideTable > tbody > tr > td:nth-child(6),
.departmentLeftSideTable > tbody > tr > td:nth-child(7),
.zoneLeftSideTable > tbody > tr > td:nth-child(1),
.zoneLeftSideTable > tbody > tr > td:nth-child(2),
.zoneLeftSideTable > tbody > tr > td:nth-child(3),
.zoneLeftSideTable > tbody > tr > td:nth-child(4),
.zoneLeftSideTable > tbody > tr > td:nth-child(5),
.zoneLeftSideTable > tbody > tr > td:nth-child(6),
.zoneLeftSideTable > tbody > tr > td:nth-child(7),
.zoneLeftSideTable > tbody > tr > td:nth-child(8) {
  background-color: white;
  z-index: 2;
  border: 0;
}
.departmentLeftSideTable > tbody > tr > td::after,
.zoneLeftSideTable > tbody > tr > td::after {
  position: absolute;
  content: "";
  border-left: 1px solid var(--border-color);
  left: 0;
  top: 0;
  height: 71px;
}

.departmentLeftSideTable > tbody > tr > td::before,
.zoneLeftSideTable > tbody > tr > td::before {
  position: absolute;
  content: "";
  top: 0;
  border-bottom: 1px solid var(--border-color) !important;
  left: 0;
  width: 100%;
}

.table.zoneLeftSideTable > :not(caption) > * > *,
.table.departmentLeftSideTable > :not(caption) > * > * {
  box-shadow: none;
}
.card .card-body .table.zoneLeftSideTable,
.card .card-body .table.departmentLeftSideTable {
  border: 0;
}

.table.zoneLeftSideTable tbody tr,
.table.departmentLeftSideTable tbody tr {
  border-bottom: 0px solid var(--border-color);
}
/* .zoneLeftSideTable > tbody > tr:nth-child(2) > td:nth-child(1) > div:nth-child(1) {
  border: 1px solid red;
  position: sticky;
  left: 8px;
}
.zoneLeftSideTable > tbody > tr:nth-child(2) > td:nth-child(1) > div:nth-child(2) {
  border: 1px solid blue;
  position: sticky;
  left: 27px;
} */
.top-first-row {
  display: flex;
}

.top-first-row .tcMatrixCheckBox.programName {
  position: sticky;
  /* left: 15px; */
  left: 0;
}

.top-first-row .unassigned-name {
  position: sticky;
  left: 50px;
}
.appUserCreation {
  column-gap: 50px;
}

.w-ThreePercent {
  width: 3%;
}
.w-TwelvePercent {
  width: 12%;
}
.w-EightPercent {
  width: 8%;
}
.w-sevenPercent {
  width: 7%;
}
.w-FivePercent {
  width: 5%;
}
.w-TenPercent {
  width: 10%;
}
.w-FifteenPercent {
  width: 15%;
}

/* .top-first-row .tcMatrixCheckBox .form-check-input:checked{
  background-color: transparent;
  border-color: transparent;
  opacity: 0;
} */

.departmentLeftSideTable > tbody > tr > td:nth-child(1),
.departmentLeftSideTable > thead > tr > th:nth-child(1) {
  position: sticky;
  left: 0;
}
.departmentLeftSideTable > tbody > tr > td:nth-child(2),
.departmentLeftSideTable > thead > tr > th:nth-child(2) {
  position: sticky;
  left: 45px;
}
.departmentLeftSideTable > tbody > tr > td:nth-child(3),
.departmentLeftSideTable > thead > tr > th:nth-child(3) {
  position: sticky;
  left: 90px;
}
.departmentLeftSideTable > tbody > tr > td:nth-child(4),
.departmentLeftSideTable > thead > tr > th:nth-child(4) {
  position: sticky;
  left: 135px;
}
.departmentLeftSideTable > tbody > tr > td:nth-child(5),
.departmentLeftSideTable > thead > tr > th:nth-child(5) {
  position: sticky;
  /* left: 255px; */
  left: 210px;
}
.departmentLeftSideTable > tbody > tr > td:nth-child(6),
.departmentLeftSideTable > thead > tr > th:nth-child(6) {
  position: sticky;
  /* left: 375px; */
  left: 285px;
}
.departmentLeftSideTable > tbody > tr > td:nth-child(7),
.departmentLeftSideTable > thead > tr > th:nth-child(7) {
  position: sticky;
  /* left: 573px; */
  left: 435px;
}

.tcMatrix-custom-notification-wrapper > div > div {
  /* border: 1px solid yellow; */
  inset: initial !important;
  bottom: 10px !important;
  right: 15px !important;
}

.tcMatrix-custom-notification {
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center;
  /* margin: auto 50px 10px auto !important; */
  border: 1px solid var(--border-color);
}
.tcMatrix-custom-notification > div:nth-child(1) {
  border: 1px solid red;
  margin: 0 !important;
  margin-right: 10px !important;
  min-width: 80px;
}

.tcMatrix-custom-notification > div:nth-child(1) > div:nth-child(1) {
  height: 30px !important;
  bottom: 11px !important;
}

.tcMatrix-custom-notification > div:nth-child(1) > div:nth-child(2) {
  top: 20px !important;
}

.tcMatrixZone .star-icon {
  font-size: 14px;
}
.tcMatrixZone.tcMatrixFirstZone.departmentTable {
  flex: 0 0 100%;
  width: 100%;
}

.tcMatrixZone.tcMatrixFirstZone.zoneTable {
  flex: 0 0 100%;
  width: 100%;
}
.zoneSwatch {
  /*width: 100%;*/
  height: 45px;
}
.star-icon-wrapper {
  margin-top: 8px;
}
.tcMatrixZone .star-icon-wrapper.red {
  background-color: #a82632;
}
.tcMatrixZone .badge-circle {
  width: 13px;
  height: 13px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  color: #ffffff;
}

.tcMatrixTable {
  display: table;
  /* border-collapse: collapse; */
  /* width: 100%; */
  position: relative;
}

.tcMatrixTableHead {
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: bold;
  background-color: #eee;
}

.tcMatrixTableRow {
  display: table-row;
}

.tcMatrixTableCell {
  display: table-cell;
  padding: 8px;
  border: 0px solid #ddd;
  vertical-align: middle;
}

.tcMatrixTableCell.first-col-checkBox {
  min-width: 40px;
  width: 40px;
  max-width: 40px;
}

.tcMatrixTableCell.firstTableBlock {
  background-color: white;
  z-index: 10;
}

.zoneHeight.tcMatrixTableRow .tcMatrixTableCell.firstTableBlock {
  border-left: 0;
}

.zoneHeight.tcMatrixTableRow .tcMatrixTableCell::after {
  position: absolute;
  content: "";
  border-left: 1px solid #ddd;
  left: 0;
  top: 0;
  height: 100%;
}

.departmentLeftSideTable .zoneHeight.tcMatrixTableRow .tcMatrixTableCell:nth-child(7):after {
  position: absolute;
  content: "";
  border-right: 1px solid #ddd;
  right: 0;
  top: 0;
  height: 100%;
}

.zoneLeftSideTable .zoneHeight.tcMatrixTableRow .tcMatrixTableCell:nth-child(8):after {
  position: absolute;
  content: "";
  border-right: 1px solid #ddd;
  right: 0;
  top: 0;
  height: 100%;
}

.zoneHeight.tcMatrixTableRow .tcMatrixTableCell::before {
  position: absolute;
  content: "";
  top: 0;
  border-bottom: 1px solid #ddd;
  left: 0;
  width: 100%;
}

.tcMatrixTableCell.stickyCell {
  position: sticky;
}

.tcMatrixtableHeader {
  color: gray;
  font-weight: bold;
}

.tcMatrixEventBar {
  position: relative;
  height: 42px;
}

.tcMatrixEventBar .tcMatrixTableCell {
  position: absolute;
  display: block;
  width: 100%;
}
.tcMatrixEventBar .tcMatrixTableCell .top-first-row {
  min-width: fit-content;
  position: sticky;
  left: 13px;
  top: 115px;
}

.employee-dob .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__leadingZero {
  /* height: 100%; */
  /* border: 1px solid red; */
  display: inline-block;
  vertical-align: baseline;
}

.scrollToTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
}

.scroll-container {
  will-change: transform;
}
.star-icon-wrapper .gray {
  color: #d7d4d4;
}
.star-icon-wrapper .green {
  color: green;
}
.star-icon-wrapper .blue {
  color: blue;
}
.star-icon-wrapper .yellow {
  color: rgb(255, 255, 0);
}
.star-icon-wrapper .maroon {
  color: rgb(168, 38, 50);
}
.star-icon-wrapper .sm {
  font-size: 12px;
}
.star-icon-wrapper .md {
  font-size: 16px;
}
.star-icon-wrapper .lg {
  font-size: 20px;
}
.questions-wrapper {
  border-bottom: 1px solid #f8f8f8;
  background-color: #f8f8f8;
  margin-bottom: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header-btns {
  padding: 6px 0px;
  align-content: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100px;
  height: 75px;
  justify-content: space-between;
}

.header-btns .header-btn-img {
  border-right: 1px solid #d7d4d4;
}

.header-btns .header-btn-labels {
  font-size: 12px;
  min-width: 75px;
  text-align: center;
  /* height: 10%; */
}

.filter-component-div {
  /* position: absolute;
  left: 0;
  z-index: 100;
  width: 100%; */
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  flex-direction: column;
  background-color: var(--main-body-bg);
  background-color: var(--main-body-bg);
  /* box-shadow: -1px 10px 10px var(--amplify-light-grey); */
}

.grouping-buttons .filter-groups {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  width: 100%;
}
.grouping-buttons .filter-groups .filter-group-labels {
  font-size: 20px;
  min-width: 100%;
}
.grouping-buttons .filter-groups .active-group-identifier {
  height: 3px;
  width: 42px;
  background-color: var(--primary-btn-color);
}
.testing-calendar-group .filter-groups,
.grouping-buttons.eosDetails .filter-groups {
  margin-bottom: 0 !important;
}
.grouping-buttons.eosDetails .filter-groups .filter-group-labels {
  font-size: 14px;
  min-width: 74%;
}
.testing-calendar-group .filter-groups .filter-group-labels {
  font-size: 14px;
}
.testing-calendar-group .filter-groups .filter-group-labels .labelConcat,
.grouping-buttons.eosDetails .filter-groups .filter-group-labels .labelConcat {
  position: absolute;
  right: 0;
}
.grouping-buttons .filter-groups .filter-group-labels {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.form-parent-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 25px;
  row-gap: 25px;
  padding: 15px;
}

.filterInputBar {
  border: 0;
  border-radius: 0;
  width: 100% !important;
  /* padding: 8px 12px; */
}
.filterInputBar::placeholder,
.filterInputBar .css-1pahdxg-control,
.filterInputBar .css-1s2u09g-control .css-319lph-ValueContainer .css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  min-width: 100%;
  max-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 42px;
}
/* .css-1w9j89e-menu:active {
  background-color: #70777f !important;
} */
.filterInputBar .css-1s2u09g-control {
  border: 0;
  border-radius: 0;
}

.FormInputGroup {
  width: 17%;
}

.text-red {
  color: var(--amplify-primary-shade);
}

.totalSelectedNumber {
  color: var(--bs-teal);
  font-size: large;
}

.eventIcon .header-btns {
  width: fit-content;
  height: fit-content;
  display: block;
  padding-right: 5px;
}

.eventIcon .header-btn-img {
  width: 30px;
  height: 30px;
  border: 0;
  margin-right: 15px;
  /* font-size: 25px;
  color: #d7d4d4; */
}

.eventIcon {
  /* align-self: flex-end; */
}

.eventIcon > .header-btns {
  display: flex;
  align-items: center;
}
.eventIcon > .header-btns > i {
  font-size: 25px;
  color: #70777f;
  display: inline;
}

.eventIcon.eventUserIcon .header-btn-img {
  border-radius: 50%;
}

.zoneCell {
  /* border: 1px solid red; */
  /* display: none; */
}

/******************************************* breakPoints ******************************/

@media only screen and (min-width: 1920px) {
  .tcMatrixZone.tcMatrixFirstZone.departmentTable {
    flex: 0 0 100%;
    width: 100%;
  }
  .tcMatrixZone.tcMatrixFirstZone.zoneTable {
    flex: 0 0 100%;
    width: 100%;
  }
}
@media only screen and (min-width: 1600px) {
  .filterDrop {
    width: 150px;
  }
}

@media only screen and (min-width: 1366px) {
  .desktop-noScroll .table-responsive {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1229px) {
  .tcMatrixZone,
  .tcMatrixWrapper {
    height: auto;
  }
  .tcMatrixEventBar .tcMatrixTableCell {
    position: absolute !important;
  }
  .tcMatrixZone.tcMatrixTable .tcMatrixTableHead,
  .tcMatrixTableRow .tcMatrixTableCell {
    position: relative;
    left: 0 !important;
  }

  .tcMatrixTableRow .tcMatrixTableCell .resizeHorizontal {
    resize: none;
    min-width: max-content;
  }

  .tcMatrixZone .table > thead > tr > .tcMatrixResizeableCell,
  .tcMatrixZone .table > tbody > tr > .tcMatrixResizeableCell,
  .tcMatrixZone .table > tbody > tr > .tcMatrixResizeableCell > .top-first-row > .tcMatrixCheckBox,
  .tcMatrixZone .table > tbody > tr > .tcMatrixResizeableCell > .top-first-row > .unassigned-name {
    position: relative;
    left: 0 !important;
  }
  .tcMatrixZone .table > tbody > tr > .tcMatrixResizeableCell > .top-first-row > .tcMatrixCheckBox > .form-check-input {
    margin-top: 0px;
    margin-left: 7px;
  }
  .tcMatrixZone .table > thead > tr > .tcMatrixResizeableCell > .resizeHorizontal,
  .tcMatrixZone .table > tbody > tr > .tcMatrixResizeableCell > .resizeHorizontal {
    resize: none;
  }

  .dateSearchBar {
    justify-content: center;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .pendingReleaseTable .user-table {
    table-layout: auto;
  }
  .table > thead > tr > th,
  .table > tbody > tr > td {
    min-width: 9rem !important;
    width: 100% !important;
    max-width: 9rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .createClientsWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .showsModal div .checkBoxWrapper span {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .labsModalWrapper {
    flex-direction: column;
  }
  .labsModalWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .uploadFilesArea {
    margin-top: 15px;
  }
  .createClientsWrapper {
    display: block;
  }
  .createClientsWrapper .leftSide,
  .createClientsWrapper .rightSide {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .createClientsWrapper .leftSide .modalPhoneInput.PhoneInput {
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
    width: 100%;
  }
  .createClientsWrapper .first-last-name-wrapper {
    display: block;
  }
  .createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .form-group-wrapper {
    display: block;
  }
  .form-group-wrapper > label {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .form-group-wrapper > input {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .scheduleView {
    min-width: 100% !important;
    /*overflow-x: auto;*/
    width: 100%;
  }

  .scheduleView .row > .col-md-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .scheduleView .row > .col-md-5 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .scheduleView .row > .col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 991px) {
  .changeRoleSelector {
    width: 100%;
  }
  .system-settings-wrapper .headerButton.btn-secondary {
    margin-bottom: 10px;
  }
  .scheduleView .row > .col-md-4.radioButtonSec {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
  }
}

@media only screen and (min-width: 577px) and (max-width: 992px) {
  .itemRowWrapper {
    overflow-x: auto;
  }
}
@media only screen and (max-width: 767px) {
  .table > thead > tr > th,
  .table > tbody > tr > td {
    min-width: 120px !important;
    width: 100% !important;
    max-width: 120px !important;
  }
  .filter-component-div .FormInputGroup {
    width: 30%;
  }
}
@media only screen and (max-width: 576px) {
  .loginWrapper {
    display: block;
    background-color: #f7f7f8;
  }
  .login-left-side {
    flex: 0 0 100%;
    max-width: 100%;
    background-color: transparent;
  }
  .login-left-side {
    min-height: 40vh;
  }
  .login-right-side {
    min-height: 60vh;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .scheduleView .schedules-header > .col-md-3,
  .scheduleView .schedules-header > .col-md-4,
  .scheduleView .schedules-header > .col-md-5 {
    padding-right: 0;
    padding-left: 0;
  }
  .scheduleView .schedules-header > .col-md-5 {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
  }
  .scheduleView .scheduleView-inner .schedule-location {
    border-radius: 0 0;
  }
  .scheduleView .row > .col-md-3,
  .scheduleView .row > .col-md-4 {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
  }
  .ml-left-desktop {
    margin-left: 0;
  }
  .scheduleView .row > .col-md-4.radioButtonSec {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .registerWrapper {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .filter-component-div .FormInputGroup {
    width: 100%;
  }
  .filter-component-div .form-parent-container {
    row-gap: 15px;
  }
}
.fa,
.fas,
.exclamation-mark {
  cursor: pointer;
}
.rbc-event-continues-after {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.customSchGlobalSearch {
  width: 100% !important;
}
.customSchGlobalSearch > div {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media only screen and (min-width: 376px) and (max-width: 576px) {
  .scheduleView .row > .col-md-4.addRemoveSec,
  .addRemoveSec {
    position: absolute;
    bottom: 0;
  }
  .scheduleView .row > .col-md-4.addRemoveSec .fa-plus,
  .addRemoveSec .fa-plus,
  .addRemoveSec .fa-trash {
    position: absolute;
    right: 45px;
    top: -22px;
  }
  .addRemoveSec .fa-trash {
    right: 70px;
  }
  .addRemoveSec.trashIconTestType .fa-trash {
    right: 15px;
    position: absolute;
    top: -22px;
  }
}

@media (max-width: 576px) {
  .signUpCodeScannerWrapper > .codeScannerWrapper > .registered-successful {
    width: auto;
  }
}

@media only screen and (max-width: 425px) {
  .login-right-side {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .scheduleView .row > .col-md-4.radioButtonSec,
  .scheduleView .row > .col-md-4.addRemoveSec {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .textAreaMessageInput .css-b62m3t-container {
    width: 100%;
  }
}
@media only screen and (max-width: 374px) {
  .headerButton {
    min-width: 120px;
  }
}

@media print {
  @page {
    margin: 0;
    padding: 0;
  }
  .modal {
    display: none;
    overflow-y: hidden !important;
  }
  .modal-xl {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .modal .modal-content .modal-body {
    padding-left: 0;
  }
  .createClientsWrapper {
    column-gap: 0;
  }

  *,
  body * {
    visibility: hidden;
  }
  .modal-header {
    display: none;
  }
  .createClientsWrapper .leftSide,
  .createClientsWrapper .rightSide {
    display: none;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #printableArea,
  #printableArea * {
    visibility: visible;
  }

  #section-to-print,
  .createClientsWrapper .printSide {
    display: block !important;
  }
  #section-to-print,
  .createClientsWrapper .printSide {
    flex: 0 0 100%;
    max-width: 100%;
    /*flex: 0 0 520px;*/
    /*max-width: 520px;*/
    width: 100%;
    position: fixed;
    inset: 0;
    height: 100%;
    /* background-color: red; */
    /* padding: 0 40px; */
  }
  .codeScannerWrapper {
    align-items: center;
    min-height: 100vh;
  }
  .registered-successful {
    font-family: sans-serif;
    text-align: center;
    width: 450px;
  }
  .registered-successful {
    width: 450px;
  }
  .registered-inner {
    padding: 30px 60px;
  }
  .qrWrapper {
    box-shadow: none;
  }
  .print-btn,
  .modal-footer {
    display: none;
  }
  /*  dtc printer*/
  .printSide .registered-successful {
    width: 280px;
    max-width: 280px;
  }
  .printSide .codeScannerWrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .printSide .mainLogo-wrapper {
    width: 100px;
    margin: auto;
  }
  .printSide .mainLogo-wrapper .img-fluid {
    width: 100px;
  }
  .printSide .registered-inner {
    padding: 5px 30px;
    width: 280px;
    max-width: 280px;
    margin: auto;
  }
  .printSide .qrWrapper {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    position: relative;
    padding-inline: 10px;
    border-radius: 10px;
    border: 1px solid lightgray;
    margin-left: 15px;
    margin-right: 15px;
  }
  .printSide .userIcon {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .printSide .qrWrapper .h4,
  .printSide h4 {
    padding-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.3;
  }
  .printSide .qrWrapper .h4 > h4 {
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .printSide .qrCode {
    width: 80px !important;
    height: 80px !important;
  }
  .printSide .qrPrintWrapper .qrCode {
    width: 80px !important;
    height: 80px !important;
    margin: auto;
  }
  .printSide .card-name {
    padding-bottom: 30px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .qrCodeWrapper.printQrCode {
    max-width: 100px;
    margin-inline: auto;
    padding-block: 5px;
    display: block;
  }
  .printQrCode #react-qrcode-logo {
    width: 75px !important;
    height: 75px !important;
  }
  .printQrCode .qrSubdiv {
    font-size: 10px !important;
    display: block;
  }
}

.imagePathField > img {
  width: 98%;
  max-height: 300px;
}
.imagePathPublic > img {
  width: 100px;
  height: 100px;
  padding: 20px;
}
.uploadImage {
  cursor: pointer;
  font-size: 14px;
  left: 5px;
  margin-top: 5px;
  background-color: #a82632;
  width: 100px;
  height: 30px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.fa-star {
  color: #fcc603;
  font-size: 11px;
}

.qrCodeWrapper {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding-top: 20px;
  padding-bottom: 20px;
}
.qrSubdiv {
  border: none;
}
.printQrCode {
  display: none;
}

.expected-result-radio > input[type="radio"]:checked + label::before {
  border-color: black;
  background-color: black;
}

/*crew-member-profile*/

.crew-member-profile .member-profile-card {
  border: 2px solid var(--card-border);
}
.crew-member-profile .member-profile-card .card-header {
  background-color: var(--primary-btn-color);
  border: 2px solid transparent;
  padding: 15px;
}
.crew-member-profile .member-profile-card .card-header .card-title {
  color: var(--text-white) !important;
  font-size: 18px;
}
.crew-member-profile .member-profile-card .card-header:first-child {
  border-radius: 0;
}
.crew-member-profile .profile-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
}
.crew-member-profile .profile-image-wrapper img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.crew-member-profile .profile-image-wrapper p {
  font-size: 14px;
  color: var(--light-gray-color);
}
.crew-member-profile .profile-image-wrapper small {
  color: var(--primary-btn-color);
}
.inputFileWrapper > img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}

.testAdmin.createClientsWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  line-height: 1;
  display: block;
}

.testAdmin.createClientsWrapper .first-last-name-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 30px;
}
.testAdmin.createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
  display: block;
}

.testAdmin.createClientsWrapper .crew-profile-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}
.testAdmin.createClientsWrapper .crew-profile-row2 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1.55fr 1.55fr 1fr;
}

.testAdmin.createClientsWrapper .form-group-wrapper > label {
  color: var(--icon-gray-color);
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 5px;
}
.testAdmin.createClientsWrapper .form-group-wrapper > label,
.testAdmin.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
}
.crew-member-profile .testAdmin.createClientsWrapper .form-group-wrapper > input {
  height: 40px;
}
.testAdmin.createClientsWrapper .form-group-wrapper > input.height-32 {
  height: 32px;
}
.form-group-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
}
.form-group-wrapper > label {
  flex: 0 0 30%;
  max-width: 30%;
  width: 100%;
  margin-top: 0;
  font-size: 14px;
}
.form-group-wrapper > input {
  flex: 0 0 calc(100% - 30% - 15px);
  max-width: calc(100% - 30% - 15px);
  width: calc(100% - 30% - 15px);
}
.form-group-wrapper.dateWrapper > input {
  flex: 0 0 calc(100% - 30% - 15px);
  max-width: calc(100% - 30% - 15px);
  width: calc(100% - 30% - 15px);
  margin-bottom: 0 !important;
}
.form-group-wrapper .textAreaWrapper {
  width: 100%;
  height: 80px;
  max-height: 80px;
  overflow-y: auto;
  resize: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-size: 15px;
  line-height: 1.2;
}
.form-group-wrapper .textAreaWrapper:hover,
.form-group-wrapper .textAreaWrapper:active,
.form-group-wrapper .textAreaWrapper:focus {
  outline: none;
}

.vaccination-rows-data {
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}
.vc-form-group > div {
  border-right: 1px solid grey;
}
.vc-form-group:last-child > div {
  border-right: 0;
}
.vc-form-group input {
  text-align: center;
}
.vc-form-group .vc-right-image {
  border-right: 1px solid grey !important;
}
.underlinedText {
  text-decoration: underline;
  color: var(--primary-btn-color) !important;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  color: var(--primary-btn-color);
}
.horizontalLoader {
  position: relative;
  left: 50%;
  right: 50%;
  color: var(--primary-btn-color);
}
.emp-updated-text {
  color: var(--primary-btn-color);
  font-size: 18px;
}

.second-column-pt {
  padding-top: 45px !important;
}

.test-details-wrapper {
  border: 1px solid #e3e3e3;
}
.test-details-wrapper .card-header {
  border-bottom: 1px solid var(--border-color) !important;
}

.test-details-header {
  padding-bottom: 15px !important;
  border-bottom: 1px solid var(--border-color) !important;
}
.test-details-wrapper .card-text {
  font-size: 14px;
}
.test-details-wrapper .card-text > span {
  font-size: 14px;
  color: var(--icon-gray-color);
  min-width: 100px;
  display: inline-block;
}
.test-details-footer {
  border-top: 1px solid var(--light-gray-color) !important;
  padding-bottom: 0;
  margin-top: 5px;
}
.test-details-footer ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.test-details-footer ul li {
  padding-block: 5px;
  padding-right: 5px;
  text-align: center;
  flex: 0 0 50%;
}
.test-details-footer ul li:not(:first-child) {
  border-left: 1px solid var(--light-gray-color);
  padding-left: 5px;
}
.test-details-wrapper .test-details-header label {
  color: var(--primary-btn-color);
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 0;
  text-transform: none;
}
.test-details-wrapper .imageWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 100px;
  max-height: 100px;
  height: 100px;
}
.test-details-wrapper .imageWrapper img {
  width: 100%;
  height: 70px;
  object-fit: contain;
}
.table-first-td:before {
  content: "";
  position: absolute;
  left: 0;
  height: 90%;
  width: 3px;
  background-color: #fcc603;
  top: 50%;
  border-radius: 5px 0 0 5px;
  transform: translateY(-50%);
}
.crew-minor-text {
  color: #ffffff;
  font-weight: 600;
}
.sidebarListing-badge {
  background-color: var(--primary-btn-color);
  color: white;
  margin-left: 5px;
  font-weight: bold;
  font-size: 13px;
  width: 20px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 1px 2px #1a1919;
}

.badge {
  width: fit-content;
}

.dashboard-wrapper {
  margin-top: 20px;
}
.dashboard-wrapper .row {
  row-gap: 10px;
}
.dashboard-wrapper .custom-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 15px 10px !important; */
}
.dashboard-wrapper .custom-card-body .detail img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.dashboard-wrapper .custom-card-body .detail .title {
  color: #464646;
  font-size: 16px;
  line-height: 1;
}
.dashboard-wrapper .custom-card-body .number {
  font-size: 24px;
  line-height: 1;
}

.dashboard-wrapper .total-test {
  background-color: #e7e4f9;
  border: 0;
}
.dashboard-wrapper .positive-test {
  background-color: #f1d8da;
  border: 0;
}
.dashboard-wrapper .negative-test {
  background-color: #dfedde;
  border: 0;
}
.dashboard-wrapper .inconclusive-test {
  background-color: rgba(59, 134, 255, 0.2);
  border: 0;
}
.dashboard-wrapper .total-number {
  color: #573bff;
}
.dashboard-wrapper .positive-number {
  color: #bf1e2e;
}
.dashboard-wrapper .negative-number {
  color: #08a003;
}
.dashboard-wrapper .inconclusive-number {
  color: #3b86ff;
}
.dashboard-wrapper > .d-flex {
  column-gap: 16px;
}
.dashboard-wrapper .stats-item {
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  flex: 0 0 calc(33% - 8px);
  border-radius: 10px;
  padding: 15px 0px;
}
.relationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f2f2f2;
  color: black;
  padding: 10px;
  border-radius: 5px;
}
.profileRelationButton {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  min-width: 20px;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
/* Custom Drop Down */
.custom-dropdown {
  opacity: 1 !important;
}
.custom-dropdown button {
  border: 0;
  padding: 0;
  opacity: 1 !important;
  background-color: white;
}
.custom-dropdown button:after {
  opacity: 0;
  display: none;
}
.custom-dropdown .dropdown-item:first-child:hover {
  border-radius: 10px 10px 0 0;
}
.custom-dropdown .dropdown-item:last-child:hover {
  border-radius: 0 0 10px 10px;
}
.eventIcon img,
.custom-dropdown button img {
  width: 20px;
}
.custom-dropdown button:hover,
.custom-dropdown button:active,
.custom-dropdown button:focus {
  background-color: white !important;
  border: 0 !important;
}

.dropdown-menu {
  display: none;
}
.dropdown-menu.show {
  opacity: 1 !important;
  z-index: 1100 !important;
  background-color: white !important;
}

.w-90 {
  width: 90% !important;
}

.eosEventBar {
  background-color: rgba(42, 214, 151, 0.2) !important;
  border-left: 4px solid rgba(42, 214, 151, 1);
  color: black;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
